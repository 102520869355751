import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import about_image from '../images/wellovci-na-terasi-well-offica-u-scc-sarajevo.jpg';
import Helmet from 'react-helmet'
import AOS from 'aos';

 class AboutUsPage extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {

    return (
      <Layout>
          <Helmet
          title="WELL – Your Digital Marketing Agency"
          meta={[
            { name: 'description', content: 'Allways WELL done - simple to most demanding websites, online stores and applications, SEO, modern methods of advertising through Google and social media.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
          ]} >
        </Helmet>
          <div id="about_us">
          <Header prefix_string_page="en"/>
            <div data-aos='fade-zoom-in'>
            
            <section id="p_about_us" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h1 className="title">About us</h1>

                <div className="content">
    <p><strong>
    Are you aware of the importance of digital media for the future of your company? 
    You want to take the first step but do not know how? You can not evaluate which personal and financial 
    resources you need to take into consideration when promoting a company or selling your products and services on 
    the Internet?
    </strong></p>
    <p> It is nothing new! These are the fundamental issues that many companies face today. The speed of Internet 
        market growth surprised even those companies that were preparing for the internet business. The scope of the 
        instruments and the opportunities offered by internet business can no longer be seen without specialized 
        marketing departments. Those companies that do not have this, or can not afford it, find a solution in co-operation 
        with digital marketing agencies who take over their marketing analyzes, design strategies and conduct marketing 
        sales activities.</p>

<h3>What can "Well" offer you?</h3>
<p>Well is one of the agencies that follow the most modern trends and offers its clients complete marketing consulting 
    services, conceiving a marketing plan and implementing it into the business ideas of its many clients. 
    From simple to most demanding websites, online stores and applications through graphic design to the positioning 
    of your website on Google (SEO) and the most modern methods of advertising through Google and social media 
    (Facebook, Instagram, LinkedIn, XING, Twitter and other) - all this belongs to the range of professional Well services.</p>

<p>Well is built on a 10-year experience and highly professional staff. Work and constant care of the business success 
    of our clients profiled us into a successfull digital marketing agency with renowned clients. The international experience 
    and multilingualism of our team - English, German, Turkish, Spanish and Slavic languages - have greatly helped us in 
    positioning in the markets of Austria, England, America and Germany, and more recently in Spain. </p>

<Link to="/portfolio/web-design" target="_blank" className="button gold is-icon-back is-arrow-right">See our portfolio<span class="icon arrow"></span></Link>
<p class="margin-top"><strong>You want to fit into modern digital streams and do not know where to start?</strong></p>
<p>Well team offers you a completely free consultation (consulting) where we:</p>
<ul>
    <li>represent our team and method of work,</li>
    <li>analyze the current state of your company, </li>
    <li>evaluate the possibilities of realizing your ideas </li>
</ul>
<p><strong>How to get in touch?</strong></p>
<p> Simply call or send us an inquiry for the appointment. We look forward to your inquiry and respond within 24 hours on a weekday.</p>
<Link to="/contact" target="_blank" className="button gold is-icon-back is-arrow-right">Make an appointment<span class="icon arrow"></span></Link>
<a href="tel:+38761787346" className="line">+387 61 787 346</a>
<a href="mailto:info@wellpromotion.ba" className="line">info@wellpromotion.ba</a>

<p>WELL TEAM WISHES YOU ALL THE BEST IN YOUR BUSINESS AND WE LOOK FORWARD TO COOPERATE WITH YOU!</p>
<img src={about_image} alt="Well Team in Sarajevo, SCC" title="Well Team in Sarajevo, SCC" />
<p class="image_alt">Well Team in Sarajevo, SCC</p>


                </div>
                </div>            
</section>
        </div>
        </div>
          <Footer/>
      </Layout>
    )
  }
}


export default AboutUsPage